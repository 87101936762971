import { getCurrentStore } from 'core/hooks/use_selector';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  GET_STORE_IMAGES,
  actionSetLastFetchedStoreImages,
  actionSetStoreImages,
} from 'silal_app_base_react/src/store/images/image_library_actions';
import { StoreDetails } from 'silal_app_base_react/src/data/types/stores';
import SolrRepository, {
  SilalImagesResponse,
} from 'silal_app_base_react/src/data/repositories/solr_repository';
import {
  SellerFilesRepository,
  SellerFilesResponse,
} from 'data/repositories/files_repository';
export function* sagaGetStoreImages() {
  function* getStoreImages(action: any) {
    try {
      const currentStore: StoreDetails = yield select(getCurrentStore);
      const storeId = currentStore.id;

      const {
        page = 1,
        limit = 100,
        query = '*',
        sortField = 'date_created',
        sortOrder = 'desc',
        is_silal_owned,
      } = action.payload.data;

      const res: SellerFilesResponse | false = yield call(
        SolrRepository.getSilalImages,
        {
          page,
          limit,
          query,
          sortField,
          sortOrder,
          is_silal_owned,
          storeId,
        },
      );

      // Type guard to check if `res` is not false
      if (res && res.files) {
        // Dispatch actions with fetched images
        yield put(actionSetStoreImages(res.files));
        yield put(actionSetLastFetchedStoreImages(new Date().getTime()));

        // Invoke success callback if provided
        if (action.payload.success) {
          action.payload.success(res);
        }
      } else {
        // Handle failure scenario when `res` is false
        console.error('Failed to fetch store images.');
        if (action.payload.failure) {
          action.payload.failure(new Error('Failed to fetch store images.'));
        }
      }
    } catch (error: any) {
      console.error('Error in sagaGetStoreImages:', error);

      // Invoke failure callback if provided
      if (action.payload.failure) {
        action.payload.failure(error);
      }
    }
  }

  yield takeLatest(GET_STORE_IMAGES, getStoreImages);
}
