import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  awardIcon_path,
  notificationIcon_path,
  cartIcon_path,
  salesIcon_path,
  starIcon_path,
  silal_logo_green,
} from 'assets/index';
import { CardStyled, Heading } from 'styles/style';
import { DashboardHeading } from 'components/show_full_elements/index';
import { DashboardCard, StatisticGraph } from '../components';
import Nav from '../components/navbar';
import {
  getCurrentOrdersState,
  getCurrentStore,
} from 'core/hooks/use_selector';
import { formatPrice } from 'silal_app_base_react/src/utils/functions/formatting_functions';
import { ORDER_STATUS } from 'silal_app_base_react/src/data/types/orders';
import { useEffect, useState } from 'react';
import { OrderBrief } from 'silal_app_base_react/src/data/types/orders';
import { actionGetAllOrders } from 'store/orders/orders_actions';
import { Helmet } from 'react-helmet-async';

const HomeScreen = () => {
  const currentStore = useSelector(getCurrentStore);
  const ordersToCalculate = useSelector(getCurrentOrdersState).orders?.filter(
    (order) => order.status === ORDER_STATUS.delivered,
  );
  const [thisMonthOrders, setThisMonthOrders] = useState(0);
  const [lastMonthOrders, setLastMonthOrders] = useState(0);
  const [thisMonthSales, setThisMonthSales] = useState(0);
  const [lastMonthSales, setLastMonthSales] = useState(0);
  const [ordersRatio, setOrdersRatio] = useState(0);
  const [salesRatio, setSalesRatio] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionGetAllOrders([]));
  }, []);
  useEffect(() => {
    calculateMetrics(ordersToCalculate);
  }, [ordersToCalculate]);
  // Function to get the month and year from a date string
  const getMonthYear = (dateString: string) => {
    const date = new Date(dateString);
    const month = date.getMonth() + 1; // Months are zero-indexed
    const year = date.getFullYear();
    return `${year}-${month < 10 ? '0' : ''}${month}`;
  };

  // Function to calculate this month and previous month orders and sales
  const calculateMetrics = (orders: OrderBrief[]) => {
    const today = new Date();
    const thisMonthYear = getMonthYear(today.toISOString());

    // Filter orders for this month and previous month
    const thisMonthOrders = orders?.filter(
      (order) =>
        getMonthYear(
          order.order_actions[order.order_actions.length - 1].action_date,
        ) === thisMonthYear,
    );

    const prevMonthYear = getMonthYear(
      new Date(today.getFullYear(), today.getMonth() - 1).toISOString(),
    );
    const prevMonthOrders = orders?.filter(
      (order) =>
        getMonthYear(
          order.order_actions[order.order_actions.length - 1].action_date,
        ) === prevMonthYear,
    );

    // Calculate this month and previous month sales
    const thisMonthSales = thisMonthOrders?.reduce(
      (total, order) => total + parseFloat(order.store_recieves),
      0,
    );
    const prevMonthSales = prevMonthOrders?.reduce(
      (total, order) => total + parseFloat(order.store_recieves),
      0,
    );

    // Calculate ratios
    const ordersRatio =
      thisMonthOrders?.length / (prevMonthOrders?.length || 1); // Avoid division by zero
    const salesRatio = thisMonthSales / (prevMonthSales || 1); // Avoid division by zero

    // Set state
    setThisMonthOrders(thisMonthOrders?.length);
    setLastMonthOrders(prevMonthOrders?.length);
    setThisMonthSales(thisMonthSales);
    setLastMonthSales(prevMonthSales);
    setOrdersRatio(ordersRatio);
    setSalesRatio(salesRatio);
    return;
  };
  return (
    <>
      <Helmet>
        <title>Dashboard | Silal Merchant</title>
      </Helmet>
      <Nav />
      <div className="between">
        <DashboardHeading
          heading={currentStore?.name_en} // TODO localize
          // hours="Peak Hours 12 AM - 2 PM"
          location={
            currentStore?.address[0]?.address_line_1 +
            ', ' +
            currentStore?.address[0]?.town?.name_en +
            ', ' +
            currentStore?.address[0]?.city?.name_en
          }
        />
      </div>
      <Row>
        <Col lg={3} md={6}>
          <DashboardCard
            icon={salesIcon_path}
            heading="Monthly Sales"
            number={formatPrice(thisMonthSales?.toString() || '0')}
            rate={salesRatio === 0 ? '0%' : (salesRatio * 100).toFixed(2) + '%'}
            rateStatus={thisMonthSales > lastMonthSales ? true : false}
          />
        </Col>
        <Col lg={3} md={6}>
          <DashboardCard
            icon={cartIcon_path}
            heading="Monthly Orders"
            number={thisMonthOrders?.toString() || '0'}
            rate={
              ordersRatio === 0 ? '0%' : (ordersRatio * 100).toFixed(2) + '%'
            }
            rateStatus={thisMonthOrders > lastMonthOrders ? true : false}
          />
        </Col>
        <Col lg={3} md={6}>
          <DashboardCard
            icon={notificationIcon_path}
            heading="Out-of-stock"
            number="6"
            rate="+ 0.9%"
            rateStatus={true}
          />
        </Col>
        <Col lg={3} md={6}>
          <DashboardCard
            icon={awardIcon_path}
            heading="Total Rating"
            number=" -- "
            rate=" -- "
            rateStatus={true}
            starI={starIcon_path}
          />
        </Col>
        <Col lg={4} md={12}>
          <CardStyled className="dasboard-card-height">
            <div className="between">
              <Heading>Hot products</Heading>
            </div>
            <div
              style={{
                height: '350px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {/* <Bar options={options} data={data} /> */}
              <img
                src={silal_logo_green}
                alt="silal_logo_green"
                height={150}
                width={150}
              />
              <br />
              <h3>Coming Soon</h3>
            </div>
            {/* <HotProduct
              img={productPlaceholder_path}
              pName="Udon tom yum with shrimps and mussels"
              price="$ 2.90"
              number="12"
            />
            <HotProduct
              img={productPlaceholder_path}
              pName="Udon tom yum with shrimps and mussels"
              price="$ 2.90"
              number="12"
            />
            <HotProduct
              img={productPlaceholder_path}
              pName="Udon tom yum with shrimps and mussels"
              price="$ 2.90"
              number="12"
            />
            <HotProduct
              img={productPlaceholder_path}
              pName="Udon tom yum with shrimps and mussels"
              price="$ 2.90"
              number="12"
            />
            <HotProduct
              img={productPlaceholder_path}
              pName="Udon tom yum with shrimps and mussels"
              price="$ 2.90"
              number="12"
            /> */}
          </CardStyled>
        </Col>
        <Col lg={8} md={12}>
          <CardStyled className="">
            <StatisticGraph />
          </CardStyled>
        </Col>
      </Row>
    </>
  );
};

export default HomeScreen;
