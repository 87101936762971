import { Link, useLocation } from 'react-router-dom';
import { NavStyled, SimpleNav } from 'styles/style';
import { Helmet } from 'react-helmet-async';

const ProfileNavBar = () => {
  const { pathname } = useLocation();

  return (
    <>
      <Helmet>
        <title>Store Profile | Silal Merchant</title>
      </Helmet>
      <SimpleNav>
        <h1>Profile</h1>
      </SimpleNav>
      <NavStyled>
        <div className="d-flex">
          <Link
            to="/profile"
            className={`nav-link-btn ${
              pathname === '/profile' ? 'active' : ''
            }`}
          >
            Profile
          </Link>
          <Link
            to="/profile/opening-hours"
            className={`nav-link-btn ${
              pathname === '/profile/opening-hours' ? 'active' : ''
            }`}
          >
            Opening hours
          </Link>
          <Link // TODO connect when implemented
            to="/profile/transactions"
            className={`nav-link-btn ${
              pathname === '/profile/transactions' ? 'active' : ''
            }`}
          >
            Transactions
          </Link>
          {/* <Link // TODO connect when implemented
            to="/profile/ledger"
            className={`nav-link-btn ${
              pathname === '/profile/ledger' ? 'active' : ''
            }`}
          >
            Ledger
          </Link> */}
        </div>
      </NavStyled>
    </>
  );
};

export default ProfileNavBar;
