import 'bootstrap/dist/css/bootstrap.min.css';
// eslint-disable-next-line
import AppRouter from 'routes/router';
import GlobalStyle from 'styles/global_styled';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { persistor, store } from 'store/store';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { ErrorBoundary } from '@sentry/react';
import { ENVIROMENT_TYPE } from 'silal_app_base_react/src/config/constants';
import { useEffect } from 'react';
import { getCurrentEnviromentType } from 'silal_app_base_react/src/utils/axios/configutation';
import { NextUIProvider } from '@nextui-org/react';
import { ConfigProvider } from 'antd';
import { Colors } from 'silal_app_base_react/src/config/theme';
import { HelmetProvider } from 'react-helmet-async';

const enableSentry = !window.location.hostname.includes('localhost');

Sentry.init({
  dsn: 'https://cdf6638b2a9c8648cb7846f5dacd723e@o4507991445667840.ingest.de.sentry.io/4507996013592656',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ], // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 0.0001,
  environment: getCurrentEnviromentType(),
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/silal\.app\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  autoSessionTracking: false,
  enabled: enableSentry,
});

const App = () => {
  useEffect(() => {
    if (getCurrentEnviromentType() === ENVIROMENT_TYPE.DEVELOPMENT) {
      if (!document.title.includes('Development')) {
        document.title += ' Development';
      }
    }

    if (window.location.hostname.includes('localhost')) {
      if (
        getCurrentEnviromentType() === ENVIROMENT_TYPE.PRODUCTION &&
        !document.title.includes('Production')
      ) {
        document.title += ' Production';
      }
      if (!document.title.includes('Local')) {
        document.title += ' Local';
      }
    }
  }, []);
  return (
    <HelmetProvider>
      <ErrorBoundary
        fallback={({ error, componentStack, resetError }) => {
          if (enableSentry) Sentry.captureException(error);
          return (
            <div>
              <h1>Something went wrong</h1>
              <pre>{(error as any)?.message}</pre>
              <pre>{componentStack}</pre>
              <button onClick={resetError}>Try again</button>
            </div>
          );
        }}
      >
        <NextUIProvider>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: Colors.greenMain,
              },
            }}
          >
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <ToastContainer
                  position="bottom-left"
                  autoClose={3500}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  // pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="light"
                />
                <GlobalStyle />
                <AppRouter />
              </PersistGate>
            </Provider>
          </ConfigProvider>
        </NextUIProvider>
      </ErrorBoundary>
    </HelmetProvider>
  );
};

export default App;

// Used for react-query, will be enabled if needed
//  const queryClient = new QueryClient({
//    defaultOptions: {
//      queries: {
//        retry(failureCount, error) {
//          if (failureCount < 1) {
//            return true;
//          }
//          const { code, message } = ErrorHandler(error);
//          toast.error(
//            `Oops ${getCurrentEnviromentType() === ENVIROMENT_TYPE.DEVELOPMENT ? 'APP_HANDLER ' : ''}! ${message}. Error code ${code}`,
//          );
//          return false;
//        },
//        throwOnError: false,
//      },
//    },
//  });
