import { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Spinner from 'silal_app_base_react/src/components/spinner';
import { useDispatch, useSelector } from 'react-redux';
import {
  cycleIcon_path,
  noOrdersIllustration_path,
  notFoundGhostIllustration_path,
} from 'assets/index';
import OrderEstimationTimeModal from '../archive/order_estimation_modal';
import { CardStyled, Heading } from 'styles/style';
import {
  AcceptedOrder,
  PickupOrder,
  ReceivedOrder,
} from '../components/current_orders_components';
import { PickupCard } from '../components/current_orders_components.styled';
import { ORDER_STATUS } from 'silal_app_base_react/src/data/types/orders';
import { OrderBrief } from 'silal_app_base_react/src/data/types/orders';
import { getCurrentOrdersState } from 'core/hooks/use_selector';
import { actionGetAllOrders } from 'store/orders/orders_actions';
import { Fonts } from 'silal_app_base_react/src/config/theme';
import { Helmet } from 'react-helmet-async';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from '@hello-pangea/dnd';
import OrdersRepository from 'data/repositories/orders_repository';
import { getLatestStatus } from 'silal_app_base_react/src/pages/orders_archive/functions/order_functions';
import { AcceptOrderModal } from '../modals/accept_order_modal';
import { toast } from 'react-toastify';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from '@nextui-org/react';

const CurrentOrder = () => {
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showReadyModal, setShowReadyModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<OrderBrief | null>(null);

  const { orders } = useSelector(getCurrentOrdersState);
  const [modalShow, setModalShow] = useState(false);
  const [localReceivedOrders, setLocalReceivedOrders] = useState<OrderBrief[]>(
    [],
  );
  const [localAcceptedOrders, setLocalAcceptedOrders] = useState<OrderBrief[]>(
    [],
  );
  const [localReadyOrders, setLocalReadyOrders] = useState<OrderBrief[]>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionGetAllOrders([]));
  }, []);

  const filterOrders = useMemo(() => {
    function sortOrders(a: OrderBrief, b: OrderBrief, index: number) {
      return (
        new Date(
          a.order_actions.find((i) => i.action === index)?.action_date ?? '',
        ).getTime() -
        new Date(
          b.order_actions.find((i) => i.action === index)?.action_date ?? '',
        ).getTime()
      );
    }
    const received = orders
      ?.filter((i) => i.status === ORDER_STATUS.requested)
      .sort((a, b) => sortOrders(a, b, ORDER_STATUS.requested))
      .reverse();
    const accepted = orders
      ?.filter((i) => i.status === ORDER_STATUS.accepted)
      .sort((a, b) => sortOrders(a, b, ORDER_STATUS.accepted))
      .reverse();
    const readyToShip = orders
      ?.filter(
        (i) =>
          i.status === ORDER_STATUS.ship__at_silal__ready ||
          i.status === ORDER_STATUS.ship__in_store__ready,
      )
      .sort((a, b) =>
        sortOrders(
          a,
          b,
          ORDER_STATUS.ship__at_silal__ready ||
            ORDER_STATUS.ship__in_store__ready,
        ),
      )
      .reverse();
    const shippedOrders = orders
      ?.filter(
        (i) =>
          i.status === ORDER_STATUS.ship__in_transit__to_silal ||
          i.status === ORDER_STATUS.ship__in_transit__to_customer,
      )
      ?.sort((a, b) =>
        sortOrders(
          a,
          b,
          ORDER_STATUS.ship__in_transit__to_silal ||
            ORDER_STATUS.ship__in_transit__to_customer,
        ),
      )
      .reverse();

    return {
      received,
      accepted,
      readyToShip,
      shippedOrders,
    };
  }, [orders]);

  useEffect(() => {
    if (filterOrders.received) {
      setLocalReceivedOrders(filterOrders.received);
    }
  }, [filterOrders.received]);

  useEffect(() => {
    if (filterOrders.accepted) {
      setLocalAcceptedOrders(filterOrders.accepted);
    }
  }, [filterOrders.accepted]);

  useEffect(() => {
    if (filterOrders.readyToShip) {
      setLocalReadyOrders(filterOrders.readyToShip);
    }
  }, [filterOrders.readyToShip]);

  const onDragEnd = async (result: DropResult) => {
    const { source, destination } = result;

    if (!destination) return;

    const allowedDrags: Record<string, string[]> = {
      received: ['accepted', 'received'],
      accepted: ['readyToShip', 'accepted'],
      readyToShip: ['readyToShip'],
      shippedOrders: [],
    };

    const sourceId = source.droppableId as string;
    const destId = destination.droppableId as string;

    if (!allowedDrags[sourceId].includes(destId)) {
      toast.warn(
        `You cannot move orders from ${sourceId} to ${destId}.\nPlease contact Silal for assistance.`,
      );

      return;
    }

    if (sourceId === destId) {
      if (sourceId === 'received') {
        const updatedReceived = Array.from(localReceivedOrders);
        const [movedOrder] = updatedReceived.splice(source.index, 1);
        updatedReceived.splice(destination.index, 0, movedOrder);

        setLocalReceivedOrders(updatedReceived);
      } else if (sourceId === 'accepted') {
        const updatedReceived = Array.from(localAcceptedOrders);
        const [movedOrder] = updatedReceived.splice(source.index, 1);
        updatedReceived.splice(destination.index, 0, movedOrder);

        setLocalAcceptedOrders(updatedReceived);
      } else if (sourceId === 'readyToShip') {
        const updatedReceived = Array.from(localReadyOrders);
        const [movedOrder] = updatedReceived.splice(source.index, 1);
        updatedReceived.splice(destination.index, 0, movedOrder);

        setLocalReadyOrders(updatedReceived);
      }
      return;
    }

    if (sourceId === 'received' && destId === 'accepted') {
      const movedOrder = filterOrders.received[source.index];

      setSelectedOrder(movedOrder);
      setShowAcceptModal(true);
    }

    if (sourceId === 'accepted' && destId === 'readyToShip') {
      {
        const movedOrder = filterOrders.accepted[source.index];
        setSelectedOrder(movedOrder);
        setShowReadyModal(true);
      }
    }
  };

  const handleAcceptOrder = async () => {
    if (!selectedOrder) return;

    await OrdersRepository.acceptOrderByID(
      selectedOrder.store_id,
      selectedOrder.id,
    );

    dispatch(actionGetAllOrders([]));
    setShowAcceptModal(false);
    setSelectedOrder(null);
  };

  const handleReadyOrder = async () => {
    if (!selectedOrder) return;

    dispatch(actionGetAllOrders([]));
    setShowReadyModal(false);
    setIsFinished(false);
    setSelectedOrder(null);
  };

  const [isFinished, setIsFinished] = useState(false);

  useEffect(() => {
    handleReadyOrder();
  }, [isFinished]);

  return (
    orders !== undefined && (
      <div className="mt-2 p-1">
        <Helmet>
          <title>Current Orders | Silal Merchant</title>
        </Helmet>
        <OrderEstimationTimeModal
          modalShow={modalShow}
          setModalShow={setModalShow}
        />
        {orders ? (
          <div>
            <DragDropContext onDragEnd={onDragEnd}>
              <Row>
                {/* Received Orders */}
                <Col lg={5} md={12}>
                  <Droppable droppableId="received">
                    {(provided) => (
                      <CardStyled
                        className="bg-grey"
                        style={{
                          display: !filterOrders.received.length ? 'flex' : '',
                          height: 'calc(100vh - 150px)',
                          overflowY: 'auto',
                          overflowX: 'hidden',
                        }}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <Heading>Received</Heading>
                        {localReceivedOrders.map((order, index) => (
                          <Draggable
                            key={order.id}
                            draggableId={order.id.toString()}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <ReceivedOrder
                                  key={order.id}
                                  orderDetail={order}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {!filterOrders.received.length && (
                          <EmptyRevievedOrders />
                        )}
                        {provided.placeholder}
                      </CardStyled>
                    )}
                  </Droppable>
                </Col>

                {/* Accepted Orders */}
                <Col lg={5} md={12}>
                  <Droppable droppableId="accepted">
                    {(provided) => (
                      <CardStyled
                        className="bg-grey "
                        style={{
                          display: !filterOrders.accepted.length ? 'flex' : '',
                          height: 'calc(100vh - 150px)',
                          overflowY: 'auto',
                          overflowX: 'hidden',
                        }}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <Heading>Accepted</Heading>
                        {localAcceptedOrders.map((order, index) => (
                          <Draggable
                            key={order.id}
                            draggableId={order.id.toString()}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <AcceptedOrder key={order.id} order={order} />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {!filterOrders.accepted.length && (
                          <EmptyAcceptedOrders />
                        )}
                        {provided.placeholder}
                      </CardStyled>
                    )}
                  </Droppable>
                </Col>

                {/* Ready for Pickup Orders */}
                <Col lg={2} md={12}>
                  <Droppable droppableId="readyToShip">
                    {(provided) => (
                      <PickupCard
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={{
                          height: 'calc(100vh - 150px)',
                          overflowY: 'hidden',
                          overflowX: 'hidden',
                        }}
                      >
                        <div className="body">
                          <Heading className="text-white">
                            Ready for Pickup
                          </Heading>
                          <Row>
                            {localReadyOrders.map((order, index) => (
                              <Col lg={12} sm={4} key={order.id}>
                                <Draggable
                                  key={order.id}
                                  draggableId={order.id.toString()}
                                  index={index}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <PickupOrder order={order} />
                                    </div>
                                  )}
                                </Draggable>
                              </Col>
                            ))}
                          </Row>
                        </div>
                        <div className="bottom center">
                          <img
                            src={cycleIcon_path}
                            style={{ padding: '5px' }}
                          />
                          <div>
                            <h2>on-way</h2>
                            <h3>{filterOrders.shippedOrders.length} orders</h3>
                          </div>
                        </div>
                        {provided.placeholder}
                      </PickupCard>
                    )}
                  </Droppable>
                </Col>
              </Row>
            </DragDropContext>
            <Modal
              isOpen={showAcceptModal}
              onClose={() => setShowAcceptModal(false)}
            >
              <ModalContent>
                <ModalHeader>Action Confirmation</ModalHeader>
                <ModalBody>
                  <p>
                    Are you sure you want to accept Order #{selectedOrder?.id}?
                  </p>
                </ModalBody>

                <ModalFooter>
                  <Button onPress={() => setShowAcceptModal(false)}>
                    Close
                  </Button>{' '}
                  <Button color="success" onPress={handleAcceptOrder}>
                    Accept
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            <Modal
              isOpen={showReadyModal}
              onClose={() => setShowReadyModal(false)}
              isDismissable={false}
            >
              <ModalContent>
                <ModalHeader>Action Confirmation</ModalHeader>
                <ModalBody>
                  <p>
                    Are you sure that order #{selectedOrder?.id} is ready for
                    pickUp?
                  </p>
                </ModalBody>

                <ModalFooter>
                  <Button onPress={() => setShowReadyModal(false)}>
                    Close
                  </Button>{' '}
                  {selectedOrder &&
                    getLatestStatus(selectedOrder.order_actions)?.action ===
                      ORDER_STATUS.accepted && (
                      <AcceptOrderModal
                        text="Ready for pickup"
                        order={selectedOrder}
                        setIsFinished={setIsFinished}
                      />
                    )}
                </ModalFooter>
              </ModalContent>
            </Modal>
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    )
  );
};

const EmptyRevievedOrders = () => (
  <div
    className="bg-transparent rounded d-flex flex-column align-items-center justify-content-center w-full"
    style={{
      fontFamily: Fonts.primary,
    }}
  >
    <img src={notFoundGhostIllustration_path} width={'70%'} alt="no-orders" />
    <h4 className="pt-3 text-center font-weight-bold">No Recieved Orders</h4>
    <h6 className="p-1 text-center font-weight-bold">
      There are no new orders right now, any new ones will appear right here.
    </h6>
  </div>
);
const EmptyAcceptedOrders = () => (
  <div
    className="bg-transparent rounded d-flex flex-column align-items-center justify-content-center w-full"
    style={{
      fontFamily: Fonts.primary,
    }}
  >
    <img src={noOrdersIllustration_path} width={'40%'} alt="no-orders" />
    <h4 className="pt-3 text-center font-weight-bold">No Accepted Orders</h4>
    <h6 className="p-1 text-center font-weight-bold">
      Take a break, there are no orders that need preparing right now.
    </h6>
  </div>
);

export default CurrentOrder;
